
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$client-primary: mat.define-palette(mat.$indigo-palette);
$client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-theme: mat.define-light-theme((
  color: (
    primary: $client-primary,
    accent: $client-accent,
    warn: $client-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($client-theme);

/* You can add global styles to this file, and also import other style files */

html, body, app-root {
    height: 100%;
  }


body{
    background-color: #eef3f4 !important;
    margin-bottom: 0;
    margin-top: 0;
}

.btn-primary{
    background-color: #9c1f2e !important;
    border-color: #9c1f2e !important;
    cursor: pointer !important;
}
.btn-primary:hover{
    background-color: #791d28 !important;
    border-color: #791d28 !important;
}

html, body { height: 100%; }

.page-container {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}

.page-header * {
    flex-grow: 2;
}

.flex-grow-2{
    flex-grow: 2;
}

.page-content {
    padding: 25px 20px 0 20px;
}

/* Inicio estilos copiados */
body {
    background-color: #f5f6f6;
    font-size: 15px;
}
table th,
table td {
    font-size: 15px !important;
}

.btn {
    font-size: 15px;
}

.btn-link,
.btn-link:hover {
    color: #172540;
}

a:focus, a:hover {
    color: #172540;
}

.page-header {
    height: 80px !important;
    background: #fff center top no-repeat url('assets/img/header.jpg');
    background-size: contain;
    padding: 25px 25px 0 25px;
    border-bottom: 1px solid #d5dadb
}

.breadcrumb {
    padding-bottom: 5px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}

.table-scrollable {
    border-radius: 8px;
    border: 1px solid #d5dadb;
}

.table-advance thead tr th {
    background-color: #DDD;
    color: #666;
    padding: 15px;
    border-left: none;
    border-right: none;
}

.table-advance tbody tr td {
    padding: 10px 15px;
    color: #172540;
}

.table-bordered > tbody > tr > td {
    background-color: #fff;
    border: 1px solid #d5dadb;
}

.td-action, .cursor-action, .td-popup {
	cursor: pointer;
}

.border-right {
	border-right-width: 1px !important;
}
.border-top {
	border-top-width: 1px !important;
}
.border-bottom {
	border-bottom-width: 1px !important;
}
.border-top-0 {
    border-top: 0 !important;
}
.nav-pills.border-bottom {
	border-bottom: 1px solid #CCC;
}
.bbn {
	border-bottom: none !important;
}

.px{
    padding: 3px;
}
.ps{
    padding: 5px;
}
.pm {
    padding: 10px;
}
.ptn {
  padding-top: 0 !important;
}
.ptx {
  padding-top: 3px !important;
}
.pts {
  padding-top: 5px !important;
}
.ptm {
  padding-top: 10px !important;
}
.ptl {
  padding-top: 20px !important;
}
.ptxl {
  padding-top: 40px !important;
}
.ptxxl {
  padding-top: 220px !important;
}
.prn {
  padding-right: 0 !important;
}
.prx {
  padding-right: 3px !important;
}
.prs {
  padding-right: 5px !important;
}
.prm {
  padding-right: 10px !important;
}
.prl {
  padding-right: 20px !important;
}
.prxl {
  padding-right: 40px !important;
}
.prxxl {
  padding-right: 220px !important;
}
.pbn {
  padding-bottom: 0 !important;
}
.pbx {
  padding-bottom: 3px !important;
}
.pbs {
  padding-bottom: 5px !important;
}
.pbm {
  padding-bottom: 10px !important;
}
.pbl {
  padding-bottom: 20px !important;
}
.pbxl {
  padding-bottom: 40px !important;
}
.pbxxl {
  padding-bottom: 220px !important;
}
.pln {
  padding-left: 0 !important;
}
.plx {
  padding-left: 3px !important;
}
.pls {
  padding-left: 5px !important;
}
.plm {
  padding-left: 10px !important;
}
.pll {
  padding-left: 20px !important;
}
.plxl {
  padding-left: 40px !important;
}
.plxxl {
  padding-left: 220px !important;
}
.mtn {
  margin-top: 0px !important;
}
.mtx {
  margin-top: 3px !important;
}
.mts {
  margin-top: 5px !important;
}
.mtm {
  margin-top: 10px !important;
}
.mtl {
  margin-top: 20px !important;
}
.mtxl {
  margin-top: 40px !important;
}
.mtxxl {
  margin-top: 220px !important;
}
.mrn {
  margin-right: 0px !important;
}
.mrx {
  margin-right: 3px !important;
}
.mrs {
  margin-right: 5px !important;
}
.mrm {
  margin-right: 10px !important;
}
.mrl {
  margin-right: 20px !important;
}
.mrxl {
  margin-right: 40px !important;
}
.mrxxl {
  margin-right: 220px !important;
}
.mbn {
  margin-bottom: 0px !important;
}
.mbx {
  margin-bottom: 3px !important;
}
.mbs {
  margin-bottom: 5px !important;
}
.mbm {
  margin-bottom: 10px !important;
}
.mbl {
  margin-bottom: 20px !important;
}
.mbxl {
  margin-bottom: 40px !important;
}
.mbxxl {
  margin-bottom: 220px !important;
}
.mln {
  margin-left: 0px !important;
}
.mlx {
  margin-left: 3px !important;
}
.mls {
  margin-left: 5px !important;
}
.mlm {
  margin-left: 10px !important;
}
.mll {
  margin-left: 20px !important;
}
.mlxl {
  margin-left: 40px !important;
}
.mlxxl {
  margin-left: 220px !important;
}

th.heading {
	background-color: #eee !important;
}

th.text-nowrap,
td.text-nowrap {
	white-space: nowrap;
}

.tabbable-visible {
	overflow: visible;
}

.page-header .page-logo .logo-default {
    margin-top: 5px !important;
    width: 300px;
}
.page-header .page-header-top .page-logo {
    float: left;
    display: block;
    width: 300px;
    height: 33px;
}

.page-header .page-header-menu{
    clear: none;
    background: #fff;
}

.dropdown-menu-minwidth {
    min-width: 225px;
}

.page-header .navbar-nav {
    padding-top: 5px;
}

.page-header .top-menu .navbar-nav > li > a {
    line-height: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.page-header-menu {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
}

.page-header-menu a {
    color: #6f6f6f;
    padding: 4px 15px 10px 15px !important;
}
.page-header-menu .dropdown-user > a {
    padding: 0 15px !important;
}
.page-header-menu .dropdown-menu a {
    padding-top: 10px !important;
}
.page-header-menu .dropdown-menu li > a {
    font-weight: 600;
    font-family: Rubik, sans-serif;
    color: #6f6f6f;
}
.page-header-menu .navbar-nav > li > a:hover {
    color: #172540;
}
.page-header-menu .nav > .active,
.page-header-menu .nav > .active > a,
.page-header-menu .nav > li > a:focus,
.page-header-menu .nav > li > a:hover,
.page-header-menu .dropdown-menu li > a:hover,
.page-header-menu .nav .open > a {
    background: none;
    color: #172540;
}

.page-header-menu .dropdown-menu > .active > a,
.page-header-menu .dropdown-menu > .active > a.active,
.page-header-menu .dropdown-menu > .active > a:hover,
.page-header-menu .dropdown-menu > .active > a:focus {
    background-color: #fff;
    color: #172540;
}

.page-header-menu .dropdown-menu > .active > a > [class^="icon-"],
.page-header-menu .dropdown-menu > li > a:hover > [class^="icon-"] {
    color: #172540;
}

.page-header .page-header-top .menu-toggler{
    background: none;
    margin-top: 15px;
    width: 23px;
    height: 23px;
}
.page-header .page-header-top .menu-toggler i{
    font-size: 23px;
}
.page-header .page-header-top .menu-toggler i:visited {
    color: #6f6f6f;
}

.page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .menu-toggler.sidebar-toggler {
    margin-right: 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle,
.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:last-child {
	padding-left: 8px;
	padding-right: 8px;
}

.portlet.light > .portlet-title.reduced {
	min-height: 35px;
	margin-bottom: 0;
}

.portlet-popup .datepicker table tr td,
.portlet-popup .datepicker table tr th {
	height: 15px;
	width: 25px;
	padding: 2px;
	font-size: 13px;
}

tr.bt-separator td,
tr.bt-separator th {
	border-top-width: 15px !important;
	border-top-color: #77c1fb !important;
}

hr.primary {
  border-color: #172540 !important;
}

.with-line {
  border-bottom: 1px solid #bfbfbf;
}

.card.image-over-card .card-image {
  height: 60%;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px; }

.card.image-over-card .card-image img {
  width: 100%;
  height: 100%;
  pointer-events: none; 
}

.greyDiv{
    background-color: #C0C0C0 !important;
}

.whiteDiv{
    background-color: #FFF !important;
}

/* Barra de título clicable */
.title-expand {
    cursor: pointer;
    padding-right: 2%;
    background: transparent url('assets/img/portlet-plus-icon-white.png') 98% center no-repeat;
}
.title-expand.title-color {
    background-image: url('assets/img/portlet-plus-icon.png');
}
.title-collapse {
    cursor: pointer;
    padding-right: 2%;
    background: transparent url('assets/img/portlet-minus-icon-white.png') 98% center no-repeat;
}
.title-collapse.title-color {
    background-image: url('assets/img/portlet-minus-icon.png');
}

.portlet.light > .portlet-title.title-reduced {
  min-height: 20px;
  margin-bottom: 20px;
}
.portlet.box.blue > .portlet-title > .caption a,
.portlet.box.blue > .portlet-title > .caption a > i {
  color: #FFFFFF;
}

.select-option-li .scroller {
  height: 250px;
}

.height-170{
    height: 170px;
}

.height-500 {
    height: 500px;
}

.row-width {
    width: 60%;
}

.margin-top-15 {
    padding-top: 15px !important;
}

.height-100{
    height: 100%;
}

.border-div {
    border: 2px solid black;
}

.position-div{
    position: static;
}

.table-layout {
    table-layout: fixed;
}

.float-none {
    float: none;
}

.border-table {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-top: 0px;
}

.space-no-wrap {
    white-space: nowrap;
}

.bg-white{
    background-color: white;
}

.text-white{
    color: white;
}

.wordBreak {
    word-break: break-all;
}

span.blue-madison {
    background-color: #3f74a3 !important;
}

span.red-flamingo {
    background-color: #ec2a15 !important;
}

span.green-seagreen {
    background-color: #1BA39C !important;
}
.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
    background-color: #acc9e2 !important;
}
.page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav > li.active > a, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav > li.active > a:hover, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav > li.current > a, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav > li.current > a:hover {
    color: white !important;
    background: #acc9e2 !important;
}
.page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav > li.active > a > i, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav > li.active > a:hover > i, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav > li.current > a > i, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav > li.current > a:hover > i {
    color: white !important;
}
.badge.badge-budget {
    background-color: #006400;
    margin-left: 5px;
    margin-top: -3px;
}
.bg-brown {
    border-color: saddlebrown !important;
    background-image: none !important;
    background-color: saddlebrown !important;
    color: #FFFFFF !important;
}
.bg-orange {
    border-color: orangered !important;
    background-image: none !important;
    background-color: orangered !important;
    color: #FFFFFF !important;
}
.bg-light-blue {
    border-color: lightskyblue !important;
    background-image: none !important;
    background-color: lightskyblue !important;
    color: #FFFFFF !important;
}
.status-label-right {
    float: right;
    padding: 6px;
    margin: 7px 0;
}
.portlet.no-title-blue {
    border: 1px solid #acc9e2 !important;
}
.portlet.no-title-blue > .portlet-body {
    border-radius: 4px !important;
}
.no-borders{
    border: 0 !important;
}
.border-left-0{
    border-left: 0;
}
.border-left-1 {
    border-left: 1px solid #313e55;
}
.border-right-1 {
    border-right: 1px solid #313e55;
}

.btn-round.btn-white {
    background-color: #FFF !important;
    border-color: #FFF;
}
.btn-round {
    border: 1px solid;
    border-radius: 17px !important;
}
label {
    color: #172540;
    font-size: 15px;
}
.portlet.light > .portlet-title > .caption > .caption-subject {
    font-size: 16px;
}
.portlet.light > .portlet-title > .caption > i {
    margin-top: 1px;
    font-size: 16px;
}
.select2-container .select2-choice,
.select2-search input,
.form-control,
.form-control input:focus {
    color: #172540;
    border-color: #d5dadb;
    font-size: 15px;
}
.form-control-transparent, .form-control-transparent:focus {
    background: transparent;
    border: 0;
}
.col-form-group {
    background-color: #fff;
    border: 1px solid #d5dadb;
    border-radius: 4px;
    padding: 3px 6px;
    display: flex;
    align-items: center;
    padding-bottom: 0;
}
.col-form-group label {
    margin-bottom: 0;
}
.col-form-group .col-input {
    flex-grow: 1;
}
.col-form-group input,
.col-form-group input:focus,
.col-form-group .select2-container .select2-choice,
.col-form-group .select2-search input,
.col-form-group .select2-drop-active {
    border-color: #fff;
    padding-left: 0;
}
.col-form-group .select2-container .select2-choice .select2-arrow{
    border-left: #fff;
}
.col-form-group .select2-container-active .select2-choice,
.col-form-group .select2-drop-active {
    border-color: #fff !important;
}
.col-form-group,
.col-form-group label {
    color: #8e96aa;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.bg-light-green {
    background-color: #e3f2dc !important;
    color: #333333 !important;
}

.bg-grey-medium {
    border-color: #767B7E !important;
    background-image: none !important;
    background-color: #767B7E !important;
    color: #ffffff !important;
}

.font-primary {
    color: #172540;
}

.w-100 {
    width: 100% !important;
}

.h-100{
    height: 100% !important;
}

.btn-header,
.btn-header:hover,
.page-header-menu a.btn-header {
    background-color: #e10b19 !important;
    color: #fff !important;
    padding-bottom: 5px !important;
    font-size: 14px;
}

.btn-red,
.btn-red:hover,
.btn-red:active,
.btn-red:focus {
    background-color: #e10b19;
    color: #FFF;
}

.btn-primary.active {
    background-color: #172540;
}

.semi-bold {
    font-weight: 600 !important;
}

@media (min-width: 991px) {
    .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
        width: 265px;
        margin: 0;
        padding: 0 20px;
    }

    .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo .logo-default {
        display: block;
    }
}

@media (max-width: 991px) {
    .page-container {
        width: 100%;
    }

    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
        display: none;
    }

    .page-header .page-logo .logo-default {
        max-width: 300px;
    }

    .page-header .page-header-menu {
        clear: both;
        background-color: #fff;
        border: 1px solid #d5dadb;
        padding-top: 10px;
        padding-bottom: 10px;
        z-index: 1000;
        position: absolute;
        top: 59px;
        left: 0;
        width: 100%;
    }

    .border-hidden-991 {
        border: 0;
    }
}

@media (max-width: 767px) {
    
    .page-header-menu .navbar-nav {
        width: 100%;
        margin-top: 0;
        padding-bottom: 0;
    }
    .page-header-menu .navbar-nav .open .dropdown-menu {
        width: 100%;
    }
    .page-header-menu .nav > li {
        width: 98%;
    }
    .page-header-menu .dropdown.open .dropdown-menu {
        display: block;
        position: relative;
        width: 97%;
        left: 0;
        clear: both;
        float: left;
        margin-bottom: 10px;
        box-shadow: none;
        border-radius: 0;
    }
    .page-header-menu .dropdown.dropdown-user .a-avatar {
        display: none;
    }
    .page-header-menu .dropdown.dropdown-user .dropdown-menu {
        display: block;
        position: relative;
        width: 97%;
        left: 0;
        clear: both;
        float: left;
        margin-top: 25px;
        margin-bottom: 10px;
        box-shadow: none;
        border-radius: 0;
        border: none;
        border-top: 2px solid #172540;
    }
    .page-header-menu .dropdown.dropdown-user .dropdown-menu li {
        display: block;
        width: 50%;
        float: left;
        text-align: center;
    }
    .page-header-menu .dropdown-menu > li:first-child:hover > a,
    .page-header-menu .dropdown-menu > li:last-child:hover > a {
        border-radius: 0;
    }
    .page-header-menu .nav.pull-right > li > .dropdown-menu::before,
    .page-header-menu .nav.pull-right > li > .dropdown-menu::after {
        content: none;
    }
    .page-header-menu .navbar-nav .open .dropdown-menu > li > a {
        color: #6f6f6f;
    }
    .page-header-menu .navbar-nav .open .dropdown-menu > li.active > a,
    .page-header-menu .navbar-nav .open .dropdown-menu > li > a:hover {
        color: #172540;
        background: none;
    }
    .page-header-menu .nav .dropdown-button {
        margin-top: 40px;
    }
    .page-header-menu .nav .dropdown-button a {
        display: inline-block;
        width: 97%;
    }
}

@media (max-width: 480px) {
    .page-header {
        padding: 5px 5px 0 5px;
        height: auto;
    }

    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > img {
        height: 20px;
    }

    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user {
        padding-top: 3px;
        padding-left: 0;
    }

    .page-header .page-header-top .menu-toggler {
        margin-top: 10px;
    }

    .page-header .page-header-menu {
        top: 33px;
    }

    .actions.with-search {
        width: 100%;
        text-align: right;
    }

    .page-header .page-logo .logo-default {
        max-width: 200px;
    }

    .page-header-menu .nav > li {
        width: 94%;
    }

    .page-header-menu .dropdown.open .dropdown-menu {
        width: 100%;
    }

    .page-header-menu .dropdown.dropdown-user .dropdown-menu {
        width: 100%;
    }
}

@media (max-width: 320px) {
    .page-header .page-logo .logo-default {
        max-width: 100%;
    }

    .page-header .page-header-menu {
        top: 64px;
    }

    .page-header .page-header-top .page-logo {
        height: auto;
    }

    .mnrxxl {
        margin-right: -11px;
    }

    .page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > .badge {
        display: none;
    }

    .page-header-menu .dropdown.dropdown-user .dropdown-menu li {
        width: 100%;
        text-align: left;
    }

    .page-header-menu .nav > li {
        width: 90%;
    }
}


.d-flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.flex-wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.justify-content-center {
    -webkit-justify-content: center;
    justify-content: center;
}

.justify-content-space-between {
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.justify-content-start {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
}

.justify-content-end {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.align-items-center {
    -webkit-align-items: center;
    align-items: center;
}

.row-gap-15 {
    grid-row-gap: 15px;
}

.column-gap-15 {
    grid-column-gap: 15px;
}

.v-align-sub{
    vertical-align: sub;
}

.font-10 {
    font-size: 10px;
}

.font-11 {
    font-size: 11px;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-23{
    font-size: 23px !important;
}

.font-25 {
    font-size: 25px !important;
}

.font-36 {
    font-size: 36px;
}

.primary-white {
    background-color: #f5f6f6 !important;
}

.no-text-decoration{
    text-decoration: none !important;
}

.overflow-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.datepicker table tr td:not(.active).today, .datepicker table tr td:not(.active).today:hover, .datepicker table tr td:not(.active).today.disabled, .datepicker table tr td:not(.active).today.disabled:hover {
    color: #fff !important;
    background-color: #172540 !important;
    border-color: #172540 !important;
}

.text-underline{
    text-decoration: underline;
}

.font-white{
    color: #fff;
}

.font-green {
    color: #71A885 !important;
}

.float-right{
    float: right;
}

.btn-red-intense, .btn-red-intense:hover, .btn-red-intense:focus {
    color: #fff;
    background-color: #e10b19;
    border-color: #e10b19;
}

.t-5-p {
    width: 5% !important;
}

.portlet-height {
    position: relative;
    overflow: hidden;
    width: auto;
    height: auto;
}

.no-padding {
    padding: 0px 0px 0px 0px !important;
}

.no-padding-top-bottom {
    padding: 8px 0px 8px 0px !important;
}

.no-border-round {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
}

.hover_primary {
    color: #202333;
    font-size: 18px;
    font-weight: 700;
}

.font-size-13 {
    font-size: 13px !important;
}

.b-gray {
    color: #818181;
    font-size: 15px;
}

.heightPortlet {
    height: 350px;
}

.pl15 {
    padding-left: 15px !important;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-top-0 {
    margin-top: 0px !important;
}

.lh-36{
    line-height: 36px !important;
}

.text-decoration-line-through{
    text-decoration: line-through !important;
}
/* Fin estilos copiados */

.table-advance tr {
    border: none !important;
    height: unset !important;
}

.portlet.box.primary {
    border: 1px solid #9c1f2e;
    border-top: 0;
}

.portlet > .portlet-body.primary, .portlet.primary {
    background-color: #9c1f2e;
}

.portlet.box.primary > .portlet-title {
    background-color: #9c1f2e;
}

.portlet.box.primary > .portlet-title > .caption > i {
    color: #FFFFFF;
}

.container.light, .container-fluid.light{
    padding: 12px 20px 15px 20px;
    background-color: #FFF;
}

.container, .container-fluid{
    border-radius: 4px;
}

.container > .accordion {
    --bs-accordion-border-color: #FFF !important;
    --bs-accordion-btn-focus-box-shadow: !important;
}

/* Estilos para el menu lateral*/

.accordion > .accordion-item > .portlet.light  > .portlet-title > .caption {
    display: block !important;
    float: inherit !important;
    margin-bottom: 15px;
    margin-top: 3px;
}

.accordion > .accordion-item > .portlet.light > .portlet-title> .caption > .accordion-button {
    background-color: #FFF !important;
    box-shadow: none !important;
}

.accordion > .accordion-item > .portlet.light >.portlet-body > .list-group {
    --bs-list-group-border-color: #FFF !important;
    --bs-list-group-border-width: 0px !important;
}

.accordion > .accordion-item > .portlet.light >.portlet-body > .list-group > .list-group-item.active {
    background-color: #9c1f2e !important;
    border-color: #9c1f2e !important;
    border-radius: 8px !important;
}

.accordion > .accordion-item > .portlet.light >.portlet-body > .list-group > .list-group-item:hover {
    border-radius: 8px !important;
}

.side-menu {
    padding: 25px 0 0px 20px;
}

/* Fin de estilos para el menu lateral*/ 

/* Estilos para el buscador*/

.accordion.search-box {
    --bs-accordion-border-color: #9c1f2e !important;
    --bs-accordion-btn-focus-box-shadow: !important;
    margin-bottom: 25px;
}

.accordion.search-box > .accordion-item > .accordion-header > button {
    background-color: #9c1f2e !important;
}

.accordion.search-box > .accordion-item > .accordion-header > button.caption {
    color: #FFF!important;
    padding: 10px 0px 10px 10px;
    font-size: 18px;
    line-height: 18px;
}

/* Fin de estilos para el buscador*/

/* Estilos para modales*/

.modal-footer, .modal-footer > button{
    flex-grow: 0 !important;
}

/* Fin de estilos para modales */

.btn-xs{
    border-radius: 3px !important;
    padding: 1px 5px !important;
    font-size: 13px !important;
}



.form-body {
    padding: 10px;
}

.form-actions{
    padding: 20px 10px;
    margin: 0;
    background-color: #f5f5f5;
    border-top: 1px solid #e5e5e5;
    border-radius: 0 0 4px 4px;
}
.form-actions.right {
    padding-left: 0;
    padding-right: 10px;
    text-align: right;
}


.accordion.search-box > .accordion-item > .accordion-header > .accordion-button:not(.collapsed):after{
    background: url("assets/img/portlet-minus-icon-white.png") 98% center no-repeat;
    margin-right: 40px;
}

.accordion.search-box > .accordion-item > .accordion-header > .accordion-button:after {
    background: url("assets/img/portlet-plus-icon-white.png") 98% center no-repeat;
    margin-right: 40px;
}

td > .badge.rounded-pill {
    font-size: 12px !important;
    font-weight: 100 !important;
}

.container-fluid.light{
    background-color: #fff;
}

.container-fluid.light > .container-title {
    padding: 0;
    min-height: 48px;
}

.container-fluid > .container-title {
    border-bottom: 1px solid #eee;
    padding: 0;
    margin-bottom: 10px;
    min-height: 41px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.container-fluid.light > .container-title > .caption {
    color: #666;
    padding: 10px 0;
}

.container-fluid > .container-title > .caption {
    float: left;
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    padding: 10px 0;
}

.container-fluid.light > .container-title > .caption > .caption-subject{
    font-size: 20px;
}

.container-fluid.light > .container-title > .caption > i{
    margin-top: 1px;
    font-size: 20px;
    font-weight: 300;
}

.container-fluid > .container-title > .caption > i{
    float: left;
    margin-top: 4px;
    display: inline-block;
    font-size: 13px;
    margin-right: 5px;
    color: #666;
}

.container-fluid.light > .container-body {
    padding-top: 8px;
}

.container-fluid > .container-body {
    clear: both;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

.container-fluid.light .form .form-body{
    padding-left: 0;
    padding-right: 0;
}

.container-fluid.light .form .form-actions.right{
    padding-right: 0;
}

.container-fluid.light .form .form-actions {
    background: none;
    padding-left: 0;
    padding-right: 0;
}

.container-fluid.light .form .form-actions {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }

.form {
    padding: 0 !important;
}

.form .form-body{
    padding: 10px;
}

.form .form-actions.right {
    padding-left: 0;
    padding-right: 10px;
    text-align: right;
}

.form .form-actions {
    padding: 20px 10px;
    margin: 0;
    background-color: #f5f5f5;
    border-top: 1px solid #e5e5e5;
    *zoom: 1;
}

.invalid-feedback{
    font-size: 14px !important;
}

.invalid-feedback:not([hidden]){
    display: block !important;
}

.btn-default {
    background-color: #fff !important;
    border-color: #ccc !important;
    border-width: 1px !important;
}

.mat-paginator-range-actions {
display: block !important;
text-align: right;
}

.mat-paginator-page-size {
    display: block !important;
}

.text-danger {
    color: #F3565D !important;
}

.app-footer {
    min-height: 0px !important;
}

.mat-dialog-container {
    padding: 0 !important;
}

.text-right {
    text-align: right;
}

.search-box .row {
    margin-bottom: 15px !important;
}

/* Apartado dropzone*/

.drop-zone {
    border: 2px dashed #ccc;
    border-radius: 8px;
    height: 500px;
    text-align: center;
}
  
.form-actions {
    background: none;
}
  
.comparison-result {
    margin-top: 20px;
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.comparison-result div {
    margin-top: 10px;
}

.line-diff {
    font-weight: bold;
}

.diff-container {
    font-family: 'Courier New', monospace;
}

.added {
    color: darkgreen;
    background-color: rgba(0, 255, 0, 0.5);
}

.removed {
    color: darkred;
    background-color: rgba(255, 0, 0, 0.5);
}

.unchanged {
    color: grey;
}

.file-container {
    background: rgba(255,255,255,0.8);
    border: 1px solid #acacac;
    border-radius: 5px;
    padding: 6px;
    max-width: 110px;
}

.file-info {
    height: 100px;
    background: #ebebeb;
    padding: 5px;
    margin-bottom: 20px;
}

.file-name {
    overflow: hidden;
}

.remove-button {
    background-image: linear-gradient(to bottom, #fafafa, #eee);
    border-radius: 4px;
    border: 1px solid #eee;
    padding: 5px;
    text-align: center;
    color: #aaa;
    overflow: hidden;
    width: 100%;
}

.remove-button:hover {
    background-image: linear-gradient(to bottom,#ccc, #e0e0e0);
    color: white;
}
/* Fin apartado dropzone */

.label {
    display: inline;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

/* Apartado tooltip */
.tooltips {
    white-space: pre-line !important;
    font-size: 12px !important;
    background: black !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.4!important;
}
/* Fin apartado tooltip */
input[type="checkbox"] + label {
    margin-left: 10px;
}

.margin-left-7{
    margin-left: 7px;
}

.t-20-p {
    width: 20%;
}

.t-10-p {
    width: 10%;
}

.align-middle{
    vertical-align: middle;
}

.bold-option{
    font-weight: 700 !important;
    color: black !important;
}

.box-overflow-visible .mat-dialog-content,
.box-overflow-visible .mat-dialog-container {
    overflow: visible !important;
}

mat-dialog-container{
    border-radius: 0.5rem !important;
    border: 1px solid #9c1f2e !important;
}

.mat-dialog-content {
    margin: 0 !important;
    padding: 0 !important;
    max-height: 100vh !important;
}